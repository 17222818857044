import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// components
import Header from "./components/Header";
import Footer from "./components/Footer";
// pages
import Main from "./pages/Main";
import Imprint from "./pages/Imprint";
import Privacy from "./pages/Privacy";
// texts
import { texts } from "./lang/texts";

const App = () => {
  const [lang, setLang] = useState("de");
  return (
    <Router>
      <div className="bg-site bg-no-repeat bg-cover overflow-hidden">
        <Header lang={lang} setLang={setLang} />
        <Routes>
          <Route path="/" element={<Main lang={lang} />} />
          <Route
            path={texts[lang].imprint.route}
            element={<Imprint lang={lang} />}
          />
          <Route
            path={texts[lang].privacy.route}
            element={<Privacy lang={lang} />}
          />
        </Routes>
        <Footer lang={lang} />
      </div>
    </Router>
  );
};
export default App;
