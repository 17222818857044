import React from "react";
import { useLocation } from "react-router-dom";
// texts
import { texts } from "../lang/texts";

const Header = ({ lang, setLang }) => {
  const handleLangSwitch = () => {
    if (lang === "de") {
      setLang("en");
    } else {
      setLang("de");
    }
  };

  const location = useLocation();
  if (
    location.pathname === "/" + texts[lang].imprint.route ||
    location.pathname === "/" + texts[lang].privacy.route
  ) {
    return null;
  }

  return (
    <header className="py-8" id="header">
      <div className="container mx-auto">
        <div className="flex flex-row-reverse justify-between items-center">
          {/* button */}
          <button onClick={handleLangSwitch} className="btn btn-sm lang-switch">
            {lang === "de" ? "DE" : "EN"}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
